import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { SaleEvent, TransactionWrapper } from '../models';
import { SeatPrices } from '../models/transaction.model';


interface CalculateNewPrices  {
  balance: number;
  priceOfSeats: number;
  seatsMap: SeatPrices;
}

@Injectable({
  providedIn: 'root'
})
export class FakeConnectionService extends ConnectionService {
  private fakeDataDir = '/assets/fake-data';
  private transaction: TransactionWrapper = null;
  private pristineTransaction: TransactionWrapper = null;
  private seats: string[] = null;
  private seatsHashmap = {};
  private seatPriceAdult: number = 34;
  private upgradesMap = new Map<string, number>();
  private balanceWithUpgrade: number = null;

  constructor(http: HttpClient) {
    super(http);
    this.http = http;
    this.seats = [];
    this.mode = 'demo';
    this.upgradesMap.set("food", 80);
    this.upgradesMap.set("cball", 60);
    this.upgradesMap.set("adult", this.seatPriceAdult);
  }

  override getOpponents(): Observable<Object> {
    return this.http.get(this.fakeDataDir + "/oponents.json");
  }

  override getEventById(): Observable<SaleEvent> {
    return this.http.get(this.fakeDataDir + "/event.json").pipe(
      tap((data: SaleEvent)=> this.eventSubject.next(data))
    );
  }

  override getGeneralAvailability(eventId: number): Observable<Object> {
    return this.http.get(this.fakeDataDir + "/availability.json").pipe();
  }

  override getMultiSectionAvailability(eventId: number, sectionsArray: string[]): Observable<object> {
    return this.http.get(this.fakeDataDir + "/multi-section-availability.json");
  }

  override getEventsList(page: number, filters: { month: string; day: string; opponent: string; }): Observable<Object> {
    return this.http.get(this.fakeDataDir + "/list-events.json");
  }

  override createTransaction(eventId: number, seatsArray: string[]): Observable<Object> {
    this.seats = seatsArray;
    return this.http.get(this.fakeDataDir + "/transaction-created.json");
  }

  override getTransactionById(transactionId: string, brainTree?: boolean): Observable<TransactionWrapper> {
    if (brainTree) {
      return of({ btToken: "no", transaction: this.transaction.transaction });
    } else if (this.transaction != null) {
      return of({ ...this.transaction })
    } else {
      return this.http.get(this.fakeDataDir + "/transaction.json").pipe(
        tap((data: TransactionWrapper)=> {
          const { transaction } = data;
          const { tdc_transaction } = transaction;
          // recalculamos el balance usando las sillas y trucamos la transaccion del json
          const { seatsMap, balance, priceOfSeats } = this.calculateNewPrices(this.seats, tdc_transaction.serviceCharges, 'adult');
          tdc_transaction.balance = balance;
          tdc_transaction.totalPriceSeats = priceOfSeats;
          // guardamos las sillas ahora
          this.seatsHashmap = seatsMap;
          transaction.seatsPrices = seatsMap;
          // guardamos copias de la transaccion
          this.transaction = JSON.parse(JSON.stringify(data));
          this.pristineTransaction = JSON.parse(JSON.stringify(data));
          this.eventSubject.next(data.transaction.event)
        })
      );
    }
  }

  override editTransactionBuyerType(transactionId: string, buyerTypeId: number, buyerTypeName: string): Observable<Object> {
    const { transaction } = this.transaction;
    const { tdc_transaction } = transaction;
    const { seatsMap, balance, priceOfSeats } = this.calculateNewPrices(this.seats, tdc_transaction.serviceCharges, buyerTypeName);
    // set nuevos precios
    tdc_transaction.balance =  balance;
    this.balanceWithUpgrade = balance;
    tdc_transaction.totalPriceSeats = priceOfSeats;
    // guardamos las sillas ahora
    transaction.seatsPrices = seatsMap;
    // actualizamos los datos del buyer type tambien
    transaction.buyerTypeName = buyerTypeName;
    transaction.buyer_type_id = buyerTypeId;
    // has rainout ???
    if (tdc_transaction.hasInsuranceRainOut) {
      const rainoutValue = this.calculateRainout(balance);
      tdc_transaction.balance += rainoutValue;
      tdc_transaction.insueranceRainOutPrice = rainoutValue;
      tdc_transaction.hasInsuranceRainOut = true;
    }
    return of({ msg: 'Upgraded', result: transaction });
  }

  override addRainoutInsuranceToTransaction(transactionId: string): Observable<Object> {
    const { transaction } = this.transaction;
    const trx = JSON.parse(JSON.stringify(transaction));
    // lets update
    const rainoutValue = this.calculateRainout(trx.tdc_transaction.balance);
    trx.tdc_transaction.balance += rainoutValue;
    trx.tdc_transaction.insueranceRainOutPrice = rainoutValue;
    trx.tdc_transaction.hasInsuranceRainOut = true;
    console.log(trx);
    return of({ msg: "Rainout added", result: trx });
  }

  override deleteRainoutInsuranceToTransaction(transactionId: string): Observable<Object> {
    this.transaction.transaction.tdc_transaction.balance = this.balanceWithUpgrade;
    this.transaction.transaction.tdc_transaction.hasInsuranceRainOut = false;
    return of({ msg: "Rainout removed", result: this.transaction.transaction });
  }

  override deleteTransaction(transactionId: string): Observable<Object> {
    console.log("CANCELADO");
    this.transaction = null;
    this.pristineTransaction = null;
    this.seats.length = 0;
    return of({"msg": "ok"});
  }

  override checkoutTransaction(transactionId: string, cardType: string, paymentNonce: string, digitalWallet: any, accountCredit: { credits: number; payFull: boolean; }, firstName: string, lastName: string, email: string): Observable<Object> {
    this.transaction.transaction.status = "OK";
    return of({ "msg": "ok" })
  }

  override getSummaryLink(eventId: number): Observable<Object> {
    return this.http.get(this.fakeDataDir + "/adlink.json");
  }


  // El rainout es el 20% del carro.
  private calculateRainout(balance: number): number {
    let val = (10 * balance) / 100
    return Math.round(val * 100) / 100
  }

  // cambiamos los precios por los del upgrade y calculamos
  private calculateNewPrices(seatsList: Array<string>, charges: number, buyerTypeName: string): CalculateNewPrices {
    const btPrice = this.upgradesMap.get(buyerTypeName);
    const priceOfSeats = (seatsList.length * btPrice);
    const seatsMap = seatsList.reduce((acum, seat) => {
      acum[seat] = { value: btPrice, "currencyCode": "USD" }
      return acum;
    }, {});
    const balance = priceOfSeats + charges;
    return { balance, priceOfSeats, seatsMap }
  }
}
