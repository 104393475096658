import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { TransactionWrapper } from '../shared/models';
import { UtilitiesService } from '../shared/services';
import { ModalsService } from '../shared/modals/modals.service';
import { ConnectionService } from '../shared/services/connection.service';
import { NgForm } from "@angular/forms";
import { PatronOktaModel } from '../okta-integration/models';
import { OktaIntegrationService } from '../okta-integration/services/okta-integration.service';
import { DigitalWalletItem, PatronModel } from "../shared/models/patron.model";

declare var braintree: any;

interface paymentDetails {
  nonce: string,
  cardType: string,
  acceptedTerms: boolean,
  firstName: string,
  lastName: string,
  email: string,
  digitalWallet: DigitalWalletItem | null,
  accountCredit: {
    credits: number,
    payFull: boolean,
  }
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  isMobile: boolean;
  patron: PatronModel;
  @ViewChild('paymentForm') form: NgForm;
  apiUrl = environment.apiUrl;
  transactionId: string;
  transaction: TransactionWrapper;
  data: { transaction: any, btToken: string };

  demoBraintreeImg: 'form' | 'card';

  paymentDetails: paymentDetails = {
    nonce: null,
    cardType: null,
    acceptedTerms: false,
    firstName: null,
    lastName: null,
    email: null,
    digitalWallet: null,
    accountCredit: {
      credits: 0,
      payFull: false,
    }
  }

  confirmationPage: boolean;

  get isDemo(): boolean {
    return this.connectionService.mode === 'demo';
  }



  constructor(private router: Router,
              private _oktaService: OktaIntegrationService,
              private connectionService: ConnectionService,
              private activatedRoute: ActivatedRoute,
              private utilitiesService: UtilitiesService,
              private modalsService: ModalsService) {
    this.data = { transaction: null, btToken: '' }
    this.demoBraintreeImg = 'form';
  }

  ngOnInit(): void {
    // Device detection
    this.isMobile = this.utilitiesService.isMobile;
    this.confirmationPage = false;

    this.transactionId = this.activatedRoute.snapshot.paramMap.get('id');
    this.connectionService.token = this.activatedRoute.snapshot.queryParams['token'];
    this.connectionService.getTransactionById(this.transactionId, true).subscribe({
      next: (response: { transaction: any, btToken: string, digitalWallets: Array<any> }) => {
        this.data = response;
        this.transaction = response;
        setTimeout(() => {
          const button = document.querySelector('#braintree-button');
          braintree.dropin.create({
            authorization: this.data?.btToken,
            selector: '#dropin-container'
          }, (err, instance) => {
            button.addEventListener('click', () => {
              if (this.isDemo) {
                // switch between images
                this.demoBraintreeImg = 'card';
                return;
              }
              instance.requestPaymentMethod((err, payload) => {
                // console.log(payload);
                // console.log('Payload brain tree -> ', payload)
                // console.log('Token para la api: ', payload.nonce)
                // console.log('Card Type para la api: ', payload.details.cardType)
                this.paymentDetails.nonce = payload.nonce;
                this.paymentDetails.cardType = payload.details.cardType.toLowerCase();
              });
            });
          });
        }, 300);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to get Transaction data.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          acceptFunction: this.onTransactionExpiredAction.bind(this)
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        };
        this.modalsService.openModal(modalData);
      }
    });
  }

  ngAfterViewInit() {
  }

  checkout() {
    //! Guarreria para la demo, si esto esta en un rama real, cuidado.
    if (this.connectionService.mode === 'demo') {
      console.log(this.connectionService.mode);
      this.connectionService.checkoutTransaction(this.transactionId, this.paymentDetails.cardType,
        this.paymentDetails.nonce, this.paymentDetails.digitalWallet, this.paymentDetails.accountCredit, this.paymentDetails.firstName,
        this.paymentDetails.lastName, this.paymentDetails.email).subscribe({
        next: response => {
          this.confirmationPage = true;
          setTimeout(() => {
            this.confirmationPage = false;
            this.router.navigate(['', 'summary', this.transactionId], { queryParams: { token: this.connectionService.token } });
          }, 4000);
        },
        error: error => {
          console.error(error);
          const modalData = {
            title: "ERROR",
            content: "An Error occurred while trying to checkout the Transaction.",
            // closeBtnName: 'CLOSE',
            acceptBtnName: 'CLOSE',
            // acceptFunction: () => {this.goTo('checkout')}
          };
          // If there's a custom api error.
          if (error.error.hasOwnProperty('code')) {
            modalData.content = error.error.message;
          }
          this.modalsService.openModal(modalData);
        }
      });
      return;
    }
    const balance = this.transaction.transaction.tdc_transaction.balance
    const { credits, payFull } = this.paymentDetails.accountCredit
    if(credits > balance) {
      const modalData = {
        title: "Account Credit",
        content: "The credit must be less than or equal to the balance.",
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    if (!this.paymentDetails.nonce && !this.paymentDetails.digitalWallet && !payFull) {
      // change message based off logged in patron
      const content = this.patron ? 'Please enter valid credit cart, select a digital wallet or pay all with credit. ': 'Please enter valid credit card.'
      const modalData = {
        title: "Payment information",
        content: content,
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    if (!this.paymentDetails.acceptedTerms) {
      const modalData = {
        title: "Accept terms",
        content: "Please accept the terms and conditions.",
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    if (!this.paymentDetails.firstName || !this.paymentDetails.lastName || !this.paymentDetails.email) {
      const modalData = {
        title: "Required fields",
        content: "You have to enter first name, last name and email.",
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    if (this.form.form.controls['email'].invalid) {
      const modalData = {
        title: "Valid email",
        content: "Please enter a valid email.",
        // closeBtnName: 'CLOSE',
        acceptBtnName: 'CLOSE',
        // acceptFunction: () => {this.goTo('checkout')}
      };
      this.modalsService.openModal(modalData);
      return;
    }
    this.connectionService.checkoutTransaction(this.transactionId, this.paymentDetails.cardType,
      this.paymentDetails.nonce, this.paymentDetails.digitalWallet, this.paymentDetails.accountCredit, this.paymentDetails.firstName,
      this.paymentDetails.lastName, this.paymentDetails.email).subscribe({
      next: response => {
        this.confirmationPage = true;
        setTimeout(() => {
          this.confirmationPage = false;
          this.router.navigate(['', 'summary', this.transactionId], { queryParams: { token: this.connectionService.token } });
        }, 4000);
      },
      error: error => {
        console.error(error);
        const modalData = {
          title: "ERROR",
          content: "An Error occurred while trying to checkout the Transaction.",
          // closeBtnName: 'CLOSE',
          acceptBtnName: 'CLOSE',
          // acceptFunction: () => {this.goTo('checkout')}
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        }
        this.modalsService.openModal(modalData);
      }
    });

  }

  goCheckout() {
    this.router.navigate([`checkout/${this.transactionId}`], { queryParams: { token: this.connectionService.token } });
  }

  onTransactionExpired() {
    const modalData = {
      title: "Transaction expired",
      content: 'The transaction has expired, please try again.',
      acceptBtnName: 'CLOSE',
      acceptFunction: this.onTransactionExpiredAction.bind(this),
    };
    this.modalsService.openModal(modalData);
  }

  goSeatSelection(){
    let eventId = 10170 //! todo hardcoded
    // let eventId: number;
    console.log(this.transaction);

    if(this.transaction) {
      eventId = this.transaction.transaction.event.pvEventId;
    // } else {
    //   eventId = this.connectionService.eventId;
    }
    this.router.navigate(['seat-selection'], {queryParams: {event: eventId}});
  }

  onTransactionExpiredAction() {
    this.connectionService.deleteTransaction(this.transactionId).subscribe({
      next: response => {
        this.goSeatSelection();
      },
      error: error => {
        // no need for a modal here
        this.goSeatSelection();
      }
    });
  }

  infoEmailModal() {
    const modalData = {
      title: "MLB Ballpark App",
      content: `Cubs tickets are available exclusively as mobile tickets via the free MLB Ballpark app. To view and use tickets, log in to your existing MLB.com account or create an account in the MLB Ballpark app with the email used to purchase your ticket(s). Detailed instructions on using the MLB Ballpark app, including email verification, viewing tickets, and forwarding tickets, are available at <a href="https://www.mlb.com/cubs/apps/ballpark?partnerId=redirect-chc-ballparkapp" target="_blank">Cubs.com/BallparkApp</a>.`,
      acceptBtnName: 'CLOSE',
    };
    this.modalsService.openModal(modalData);
  }

  oktaEventEmitter(instance: any): void {
    let patron = instance as PatronOktaModel;
    this.connectionService.postAssociatePatronToCart(patron.sub, parseInt(this.transactionId, 10), this.connectionService.token).subscribe({
      next: (response: PatronModel) => {
        this.patron = response;
        this.paymentDetails.firstName = response.firstName;
        this.paymentDetails.lastName = response.lastName;
        this.paymentDetails.email = response.email;
      },
      error: (error) => {
        console.log(error);
        const modalData = {
          title: "ERROR",
          content: 'An Error occurred while trying to change upgrades',
          acceptBtnName: 'CLOSE',
          // acceptFunction: ,
        };
        // If there's a custom api error.
        if (error.error.hasOwnProperty('code')) {
          modalData.content = error.error.message;
        }
        if (error.error.hasOwnProperty('code') && error.error.code === 'E_TRANSACTION_RESTART') {
          modalData['acceptFunction'] = () => {
            this.restartTransaction();
          }
        }
        this.modalsService.openModal(modalData);
      }
    })
  }

  onChangeDigitalWalletSelector(instance): void {
    this.paymentDetails.cardType = this.paymentDetails.digitalWallet? this.paymentDetails.digitalWallet.cardType.description: null;
  }

  checkboxPayInFullChange(instance: any): void {
    if (instance.currentTarget.checked && this.patron.accountCredit >= this.transaction.transaction.tdc_transaction.balance ) {
      this.paymentDetails.accountCredit.credits = this.transaction.transaction.tdc_transaction.balance
    } else if (instance.currentTarget.checked && this.patron.accountCredit <= this.transaction.transaction.tdc_transaction.balance) {
      this.paymentDetails.accountCredit.credits = this.patron.accountCredit
    } else if (!instance.currentTarget.checked) {
      this.paymentDetails.accountCredit.credits = 0
    }
  }

  private restartTransaction() {
    this.connectionService.deleteTransaction(this.transactionId).subscribe({
      next: response => {
        this.goSeatSelection();
      },
      error: error => {
        // no need for a modal here
        this.goSeatSelection();
      }
    });
  }

  onBlurInputCredits() {
    if (this.paymentDetails.accountCredit.credits > this.patron.accountCredit) {
      this.paymentDetails.accountCredit.credits = this.patron.accountCredit;
    }
  }

  get substractCreditsToBalance(): number {
    const balance = this.transaction?.transaction.tdc_transaction.balance;
    const result = balance - this.paymentDetails.accountCredit.credits;
    return (result > 0) ? result : 0;
  }

  get showCreditsApplies(): number {
    const credits = this.paymentDetails.accountCredit.credits;
    return (credits && credits > 0) ? credits : 0
  }
}
