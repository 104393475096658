<div *ngIf="!confirmationPage" id="payment" class="h-full bg-gray-100 overflow-y-auto">
  <!-- Back Button -->
  <div *ngIf="!isMobile" class="m-5 mb-3">
    <button class="btn-white" (click)="goCheckout()">
      <span class="mr-4"><img src="/assets/img/arrow-left-black-icon.svg" alt=""></span>
      Back
    </button>
  </div>

  <div [ngClass]="isMobile? 'w-full px-5 py-7': 'w-288 2xl:w-352 px-20 py-14 pt-0 mx-auto'">
    <div [ngClass]="isMobile? 'mb-7 text-center': 'mb-3'">
      <h1 class="text-4xl" [ngClass]="{'mb-1': isMobile}">Payment</h1>
      <div class="flex flex-row justify-between items-baseline mt-3">
        <app-timer (transactionExpired)="onTransactionExpired()" [createdTime]="this.transaction?.transaction.created_at"></app-timer>
        <app-okta-container (patronEmmiter)="oktaEventEmitter($event)"></app-okta-container>
      </div>
    </div>
    <!-- IF PATRON IS LOGGED WITH OKTA, SHOW IT -->
    <div *ngIf="patron" class="w-100 flex flex-row box-white px-6 py-4 mb-3">
      <!-- ACCOUNT CREDIT COLUMN-->
      <div class="flex flex-col account-credit-col w-1/2">
        <div class="title">Account Credit</div>
        <div class="sub-title mb-5"><b>Your account’s available credit: {{ patron.accountCredit }}</b><br>
          Please select the amount you would like to use:</div>
        <div class="w-full flex flex-row items-baseline">
          <div>$ <input type="text" value="0" class="selector-filter mr-5" (blur)="onBlurInputCredits()" [(ngModel)]="paymentDetails.accountCredit.credits"></div>
          <div><input type="checkbox" name="payInFull" [(ngModel)]="paymentDetails.accountCredit.payFull" (change)="checkboxPayInFullChange($event)"> <span class="ml-5">Use all available credit</span></div>
        </div>
      </div>
      <!-- DIGITAL WALLET COLUMN-->
      <div class="flex flex-col digital-wallet-col w-1/2 ml-10">
        <div class="title">Payment Options</div>
        <div class="sub-title mb-5">
          <b>Total amount due: {{ transaction?.transaction.tdc_transaction.balance | currency:'USD': 'symbol':'1.2-2' }}</b> <br>
          Credit applied: {{ showCreditsApplies | currency:'USD': 'symbol':'1.2-2' }} <br>
          Remaining balance: {{ substractCreditsToBalance | currency:'USD': 'symbol':'1.2-2' }} <br>
          Please select the digital wallet card you would like to use:</div>
        <div>
          <select [(ngModel)]="paymentDetails.digitalWallet" (change)="onChangeDigitalWalletSelector($event.target)" name="digitalWallet" class="selector-filter w-full">
            <option [ngValue]=null>Select your Digital wallet</option>
            <option [ngValue]="item" *ngFor="let item of patron.digitalWalletList">{{item.nickname}}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- Payment Options -->
    <div class="box-white w-full" [ngClass]="isMobile? 'px-4 py-2': 'px-6 py-4 payment-box'">
      <form #paymentForm="ngForm">
        <div class="flex" [ngClass]="isMobile? 'flex-wrap': 'h-full'">

          <div class="my-2" [ngClass]="isMobile? '': 'w-1/2 h-auto flex flex-col'">
            <!-- Checkout Recap -->
            <div class="mb-6">
              <div class="px-4 py-3 bg-gray-200 rounded-lg" [ngClass]="isMobile? 'mb-6': 'mb-8'">
                <h4 class="font-bold">Total amount due: ${{ transaction?.transaction.tdc_transaction.balance | number: '1.2-2' }}</h4>
              </div>
              <div class="w-full mb-4">
                <div class="px-3 py-2 mb-2 text-xs bg-blue-off-white rounded-lg flex justify-between items-center">
                  <p>Seats</p>
                  <p class="font-bold text-blue">${{ transaction?.transaction.tdc_transaction.totalPriceSeats | number: '1.2-2' }}</p>
                </div>
              </div>
              <div class="w-full mb-4">
                <div class="px-3 py-2 mb-2 text-xs bg-blue-off-white rounded-lg flex justify-between items-center">
                  <p>Per ticket fees</p>
                  <p class="font-bold text-blue">${{ transaction?.transaction.tdc_transaction.serviceCharges | number: '1.2-2' }}</p>
                </div>
              </div>
              <div class="w-full mb-4">
                <div class="px-3 py-2 mb-2 text-xs bg-blue-off-white rounded-lg flex justify-between items-center">
                  <p>Amusement tax (12% of seat cost):</p>
                  <p class="font-bold text-blue">${{ transaction?.transaction.tdc_transaction.amusementTax | number: '1.2-2' }}</p>
                </div>
              </div>
              <div class="w-full mb-4" *ngIf="transaction?.transaction.tdc_transaction.hasInsuranceRainOut">
                <div class="px-3 py-2 mb-2 text-xs bg-blue-off-white rounded-lg flex justify-between items-center">
                  <p>Rainout insurance</p>
                  <p class="font-bold text-blue">${{ transaction?.transaction.tdc_transaction.insueranceRainOutPrice | number: '1.2-2' }}</p>
                </div>
              </div>
            </div>

            <!-- Terms&Conditions -->
            <div [ngClass]="isMobile? 'mb-4': 'mb-2'">
              <div class="flex items-center mb-1">
                <input type="checkbox" class="w-4 h-4" placeholder="" id="marketing" name="marketing" [value]=true
                        [(ngModel)]="paymentDetails.acceptedTerms">
                <label for="marketing" class="ml-2 text-xs text-gray-600 font-bold">I agree to the following</label>
              </div>
              <div class="h-32 italic text-gray-600 overflow-y-auto">
                <p class="mb-3">
                  As part of your ticket purchase, you are required to acknowledge the
                  <a href="https://www.mlb.com/cubs/tickets/terms-and-conditions" target="_blank">Chicago Cubs Ticket Terms and conditions</a>
                  The Terms and conditions of your ticket and policies applicable to its use are updated to
                  address circumstances related to COVID-19, health and safety, and seating pods. By clicking the Place Order
                  button, you agree to accept all
                  <a href="https://www.mlb.com/cubs/tickets/terms-and-conditions" target="_blank">Terms and Conditions</a> applicable to
                  ticket purchase, and all related policies.
                  Further, due to the COVID-19 pandemic, scheduling may be impacted and games may be canceled, rescheduled,
                  postponed or games may be held with limited capacity. Games may also have restricted capacity that prevents
                  the use of specific seats. In the event that the game(s) for which the ticket(s) is issued does not occur or
                  you are unable to use the specific seat(s) purchased, a refund or credit will be issued according to the
                  refund policy available at
                  <a href="http://www.cubs.com/" target="_blank">www.cubs.com.</a>
                </p>
                <p class="mb-3">
                  By selecting the Place Order button you agree to purchase the tickets selected, to accept any restrictions
                  noted, and understand you are responsible for any payment needed to complete the purchase. Upon completing
                  your purchase by selecting the Place Order button, you acknowledge and agree your credit card will be
                  charged. All sales are final. All tickets are subject to the
                  <a href="https://www.mlb.com/cubs/tickets/terms-and-conditions" target="_blank">Terms and Conditions</a>
                </p>
                <p>
                  In connection with your purchase, you may receive customer service-related emails from the Chicago Cubs,
                  including optional surveys regarding your gameday experience. You also may receive ticket-related commercial
                  email from the Chicago Cubs. You may unsubscribe from ticket-related commercial emails at any time.
                </p>
              </div>
            </div>
          </div>

          <!-- Guest/Customer data -->
          <div id="braintree" [ngClass]="isMobile? 'mb-2': 'w-1/2 ml-10 mb-4'">
            <div [ngClass]="{'mb-2': isMobile}">
              <div [ngClass]="isMobile? '': 'mb-4 flex'">
                <div class="w-full">
                  <label class="label" for="first-name">First name</label>
                  <input type="text" class="input" placeholder="First name" id="first-name" name="first-name"
                          [(ngModel)]="paymentDetails.firstName" [readOnly]="patron">
                </div>
                <div class="w-full" [ngClass]="isMobile? 'my-3': 'ml-6'">
                  <label class="label" for="last-name">Last name</label>
                  <input type="text" class="input" placeholder="Last name" id="last-name" name="last-name"
                          [(ngModel)]="paymentDetails.lastName" [readOnly]="patron">
                </div>
              </div>
              <div [ngClass]="isMobile? 'mb-3': 'mb-4'">
                <div class="w-full">
                  <label class="label" for="email">Email</label>
                  <input type="email" class="input" placeholder="example@gmail.com" id="email" name="email"
                          [(ngModel)]="paymentDetails.email" email [readOnly]="patron">
                </div>
                <p class="mt-1">*Tickets will be delivered to this email in the MLB Ballpark App. <button class="btn-icon w-4 h-4 text-white inline-flex bg-blue rounded-full" (click)="infoEmailModal()">i</button></p>
              </div>
            </div>

            <!-- Braintree -->
            <ng-container *ngIf="!isDemo;else demoBraintree">
              <div id="dropin-container" [class.hidden]="paymentDetails.digitalWallet != null"></div>
            </ng-container>
            <ng-template #demoBraintree>
              <img src="/assets/img/demo/braintree-form.png" alt="braintree-form" *ngIf="demoBraintreeImg === 'form'">
              <img src="/assets/img/demo/braintree-verify.png" alt="braintree-form" *ngIf="demoBraintreeImg === 'card'">
            </ng-template>
            <div>
              <button id="braintree-button" class="btn-blue bg-blue-light ml-auto" [class.hidden]="paymentDetails.digitalWallet != null">Use this card</button>
            </div>
          </div>

        </div>
      </form>
    </div>

    <div class="mt-7 flex" [ngClass]="isMobile? 'justify-center': 'justify-end'">
      <button class="btn-black disabled:bg-gray-600" (click)="checkout()">
        Checkout
        <span class="ml-4"><img src="/assets/img/arrow-right-white-icon.svg" alt=""></span>
      </button>
    </div>

  </div>
</div>

<div *ngIf="confirmationPage" id="confirmation" class="h-full flex justify-center items-center bg-green-off-white" [ngClass]="isMobile? 'px-8 py-10': 'px-20 py-14'">
  <div class="box-white py-12 text-center" [ngClass]="isMobile? 'w-full h-auto px-14': 'w-128 h-72 px-20'">
    <img class="m-auto" src="/assets/img/checkmark-green-icon.svg" alt="Green checkmark icon">
    <h2 class="my-4">Order Placed!</h2>
    <h4 class="font-normal text-gray-500">Your order was placed successfully.</h4>
  </div>
</div>
