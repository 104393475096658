import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { CustomerAddress } from '../models/customerAddress.model';
import { SaleEvent, TransactionWrapper } from "../models";
import { environment } from 'src/environments/environment';
import { DigitalWalletItem } from "../models/patron.model";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  mode: 'demo' | 'real';

  apiURL = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': `application/json`
    })
  };
  token: string;

  eventId: number;
  eventSubject: Subject<SaleEvent>

  constructor(protected http: HttpClient) {
    this.eventSubject = new Subject<SaleEvent>();
    this.mode = 'real';
  }

  // General - Send Request
  private sendRequest(endpoint, body = null) {
    const finalUrl = `${endpoint}`;
    const observable = body ? this.http.post(finalUrl, body, this.httpOptions) : this.http.get(finalUrl, this.httpOptions);
    return observable.pipe();
  }


  // EVENTS
  // Get Events by ID
  // GET - /events/:id/
  // Recupera todo los datos del evento juntos sus upgrades y upsell
  getEventById(eventId: number) {
    const endpoint = `${this.apiURL}/events/${eventId}`;
    return this.sendRequest(endpoint).pipe(
      tap((data: SaleEvent)=> this.eventSubject.next(data)));
  }

  // Get events list
  // GET - /events/
  // param page
  // Recupera la lista de eventos para la landing
  getEventsList(page=1, filters: { month: string; day: string; opponent: string; }) {
    let endpoint = `${this.apiURL}/events?page=${page}`;
    for (let [key, value] of Object.entries(filters)) {
      if (value === 'none') continue;
      endpoint += `&${key}=${value}`;
    }
    return this.sendRequest(endpoint)
  }

  getOpponents() {
    let endpoint = `${this.apiURL}/events/opponents`
    return this.sendRequest(endpoint)
  }

  getEvent(){
    return this.eventSubject.asObservable();
  }


  /**
   * Give okta id, receive patron data (digital wallet and credit available)
   * @param {string} id
   * @deprecated
   **/
  getPatronUsingOkta(id: string) {
    let endpoint = `${this.apiURL}/customer/identity/${id}`;
    return this.sendRequest(endpoint)
  }

  postAssociatePatronToCart(patronOktaId: string, transactionId: number, token: string) {
    let endpoint = `${this.apiURL}/transaction/${transactionId}/add/patron?t=${token}`;
    return this.sendRequest(endpoint, { customer: patronOktaId })
  }


  // AVAILABILITY

  // Get Availability by Event
  // GET - /events/:id/availability
  // Recupera todas las secciones disponibles desde PV
  getGeneralAvailability(eventId: number) {
    const endpoint = `${this.apiURL}/events/${eventId}/availability`;
    return this.sendRequest(endpoint);
  }

  // Get Availability by Event and Section
  // GET - /events/:id/availability/:section
  // Disponibilidad de una seccion dada por Provenue
  // getSectionAvailability(eventId: number, sectionId: string) {
  //   const endpoint = `${this.apiURL}/events/${eventId}/availability/${sectionId}`;
  //   return this.sendRequest(endpoint);
  // }

  // Get (Post) availability for multi-section
  // POST - /events/:id/availability/multi-section
  //  En body pasamos una lista de section id, y se devuelve la disponibilidad de sillas para cada seccion
  // {
  //     "sectionList": ['S_1', 'S_2', ...]
  // }
  getMultiSectionAvailability(eventId: number, sectionsArray: string[]) {
    if (!sectionsArray.length) return new Observable(r => r.error('Sections array is empty.'));
    const endpoint = `${this.apiURL}/events/${eventId}/availability/multi-section`;
    const body = { sectionList: sectionsArray };
    return this.sendRequest(endpoint, body);
  }


  // TRANSACTIONS

  // Get transaction by ID
  // GET - /transaction/:id?t={{token}}
  // Devuelve la transacion, junto la tdc_transaction ademas de la informacion del Evento
  getTransactionById(transactionId: string, brainTree= false){
    const endpoint = `${this.apiURL}/transaction/${transactionId}?t=${this.token}&braintree=${brainTree}`;
    return this.sendRequest(endpoint).pipe(
      tap((data: TransactionWrapper)=> this.eventSubject.next(data.transaction.event)));
  }

  // Create transaction
  // POST - /transaction
  // Creamos la transaccion, hacemos lock de los seats en Provenue
  // La response devolvera, id, status y **token**
  // El token es unico por transaccion y se debe pasar para poder realizar peticiones.
  // {
  //     "seats": ["S_102-2-11"],
  //     "buyerType": "3161",
  //     "eventId" :{{eventId}}
  // }
  createTransaction(eventId: number, seatsArray: string[]) {
    if (!seatsArray.length) return new Observable(r => r.error('Seats array is empty.'));
    const endpoint = `${this.apiURL}/transaction`;
    const body = { seats: seatsArray, eventId };
    return this.sendRequest(endpoint, body);
  }

  // Edit transaction with new buyer type
  // PATCH - /transaction/:id?t={{token}}
  // Volvemos a crear el carro en PV con el nuevo buyer type. Y actualizamos los datos que tenemos en nuestra bdd
  // {
  //     "buyerType": 4222
  // }
  editTransactionBuyerType(transactionId: string, buyerTypeId: number, buyerTypeName: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?t=${this.token}`;
    const body = { 'buyerType': {id: buyerTypeId, name: buyerTypeName }};
    return this.http.patch(endpoint, body, this.httpOptions);
  }

  // Add Rainout Insurance to cart
  // POST - /transaction/:id/add/rainout-insurance?t={{token}}
  // Añade el evento 'Rainout Insurance' al carro. Se edita el precio del evento sera el 10% del total del balance.
  addRainoutInsuranceToTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}/add/rainout-insurance?t=${this.token}`;
    return this.sendRequest(endpoint, {});
  }
  // delete rainout insurance
  // /transaction/:id/delete/rainout-insurance/
  deleteRainoutInsuranceToTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}/delete/rainout-insurance?t=${this.token}`;
    return this.http.delete(endpoint, {})
  }

  // Delete transaction
  // DELETE - /transaction/:id?t={{token}}
  // Cancela la transaccion y cancela el cartId
  deleteTransaction(transactionId: string) {
    const endpoint = `${this.apiURL}/transaction/${transactionId}?t=${this.token}`;
    return this.http.delete(endpoint, this.httpOptions);
  }

  // CHECKOUT

  // Checkout
  // POST - /transaction/:id/checkout?t={{token}}
  // Hacemos el checkout en Provenue adjuntando los datos de pago. Y patronId de la persona que esta haciendo el pago.
  // {
  //     "payments": {
  //         "cardType": "visa",
  //         "paymentNonce": "tokenfiudjgijdigjdigjdi",
  //         "digitalWallet": null
  //     }
  // }
// {
//   "customer": {
//     "firstName": "isidro",
//     "lastName": "font",
//     "email": "isidro@mobilemediacontent.com"
//   },
//   "payments": {
//     "cardType": "visa",
//     "paymentNonce": "tokencc_bh_qn9kqt_chqp3f_4z4jm8_9c5c24_h62",
//     "digitalWallet": null
//   }
// }
  checkoutTransaction(transactionId: string, cardType: string, paymentNonce: string, digitalWallet: DigitalWalletItem | any,
                      accountCredit: { credits: number, payFull: boolean}, firstName: string, lastName: string, email: string) {
    const { credits, payFull } = accountCredit
    const endpoint = `${this.apiURL}/transaction/${transactionId}/checkout?t=${this.token}`;
    if (paymentNonce && digitalWallet) {
      paymentNonce = null;
      cardType = digitalWallet.cardType.description
    }
    const body = {
      payments: {
        cardType: (cardType) ? cardType.toLocaleLowerCase(): null,
        paymentNonce,
        digitalWalletId: (digitalWallet) ? digitalWallet.id : null,
        credit: credits, // dont send pay in full, now is checking in api
      },
      customer: {
        firstName,
        lastName,
        email
      }
    };
    return this.sendRequest(endpoint, body);
  }

  // CUSTOMER

  // Create patron
  // POST - /customer/
  // Crea el customer en Provenue.
  // {
  //   "customerAddress": {
  //       "address": "saba street",
  //       "city": "Sabadell",
  //       "countryCode": "ESP",
  //       "postalCode": "08822",
  //       "subCountryCode": "CA"
  //   },
  //   "phone": "0987654321",
  //   "firstName": "John",
  //   "lastName": "Porrington",
  //   "email": "jporras@mobilemediacontent.com"
  // }
  createCustomer(email: string, firstName: string, lastName: string, phone: string, customerAddress: CustomerAddress) {
    const endpoint = `${this.apiURL}/customer`;
    const body = { email, firstName, lastName, phone, customerAddress };
    return this.sendRequest(endpoint, body);
  }

  // Search customer by email
  // POST - /auth/search?email=email_de_ejemplo@mobilemediacontent.com
  // Busca en provenue un patron con ese email una vez encontrado devuelve al front patronId y emial
  searchCustomerByEmail(email: string) {
    const endpoint = `${this.apiURL}/auth/search?email=${email}`;
    return this.sendRequest(endpoint, {});
  }

  // Summary Links
  getSummaryLink(eventId: number) {
    const endpoint = `${this.apiURL}/events/${eventId}/summary-links`;
    return this.sendRequest(endpoint);
  }
}
