import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/shared/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  switchColor: boolean;

  get isMobile() {
    return this.utilitiesService.isMobile
  }

  constructor(private router: Router, private utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.switchColor = event.url.includes('seat-selection');
      }
    });
  }

}
