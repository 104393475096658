import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FiltersComponent } from './seat-selection/filters/filters.component';
import { CartComponent } from './seat-selection/cart/cart.component';
import { SeatSelectionComponent } from './seat-selection/seat-selection.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentComponent } from './payment/payment.component';
import { SummaryComponent } from './summary/summary.component';
import { LayoutComponent } from './layout/layout.component';
import { DvmComponent } from './seat-selection/dvm/dvm.component';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from "@angular/common/http";
import { NpnSliderModule } from 'npn-slider';
// import { NpnSliderComponent } from './shared/components/npn-slider/npn-slider.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalsComponent } from './shared/modals/modals.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GaModalComponent } from './shared/modals/ga-modal/ga-modal.component';
import { SectionPopoverComponent } from './digital-venue/popovers/section-popover/section-popover.component';
import { SeatPopoverComponent } from './digital-venue/popovers/seat-popover/seat-popover.component';
import { TimerComponent } from './shared/components/timer/timer.component';
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { UpsellsComponent } from './seat-selection/upsells/upsells.component';
import { NumbersInputDirective } from './shared/directives/numbers-input.directive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TutorialComponent } from './tutorial/tutorial.component';
import { EventLandingComponent } from './event-landing/event-landing.component';
import { SaleEventComponent } from './event-landing/sale-event/sale-event.component';
import { PaginationModule } from './layout/pagination/pagination.module';
import { FooterComponent } from './layout/footer/footer.component';
import { TutorialWelcomeModalComponent } from './shared/modals/tutorial-welcome-modal/tutorial-welcome-modal.component';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { OktaIntegrationModule } from "./okta-integration/okta-integration.module";
import { ConnectionService } from './shared/services/connection.service';
import { FakeConnectionService } from './shared/services/fake-connection.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FiltersComponent,
    CartComponent,
    SeatSelectionComponent,
    CheckoutComponent,
    PaymentComponent,
    SummaryComponent,
    LayoutComponent,
    DvmComponent,
    LoginComponent,
    // NpnSliderComponent,
    ModalsComponent,
    GaModalComponent,
    SectionPopoverComponent,
    SeatPopoverComponent,
    TimerComponent,
    UpsellsComponent,
    NumbersInputDirective,
    TutorialComponent,
    EventLandingComponent,
    SaleEventComponent,
    FooterComponent,
    TutorialWelcomeModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NpnSliderModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    LoadingBarHttpClientModule,
    LoadingBarModule,
    PaginationModule,
    NgxSliderModule,
    OktaIntegrationModule,
  ],
  providers: [
    { provide: ConnectionService, useClass: FakeConnectionService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
